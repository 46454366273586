<template>
  <div>
    <v-overlay :value="loading" absolute>
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <p class="mt-3">Buscando el documento...</p>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { webserver, fe_ws } from "@/services/webserver.js";
import createDoc from "@/utils/create_doc.js";
// import docs_lst from "../../utils/docs.js";
import dianSettings from "../../utils/settings.js";

export default {
  name: "DocumentViewer",
  props: {
    encryptedId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      item: createDoc(),
      decryptedId: null,
      document: null,
      loading: true,
      error: null,
      // docTypes: docs_lst().docType,
      invoiceImg: null,
      settings: dianSettings(),
      columns: {
        item: { x: 10, width: 50 },
        description: { x: 150, width: 500 },
        quantity: { x: 800, width: 100 },
        value: { x: 900, width: 275 },
      },
      FONT_SIZES: {
        LARGE: "bold 60px 'Roboto Mono', monospace",
        MEDIUM: "bold 60px 'Roboto Mono', monospace",
        SMALL: "bold 60px 'Roboto Mono', monospace",
      },
    };
  },
  created() {
    this.loadDocument();
  },
  methods: {
    decryptId(encryptedId) {
      const key = "AiP05KeyAiP05KeyAiP05KeyAiP05Key";
      try {
        const ciphertext = atob(decodeURIComponent(encryptedId));
        const ivLength = 16; // AES block size
        const hmacLength = 32; // SHA256 digest size

        const iv = CryptoJS.enc.Latin1.parse(ciphertext.slice(0, ivLength));
        const hmac = ciphertext.slice(ivLength, ivLength + hmacLength);
        const ciphertextRaw = CryptoJS.enc.Latin1.parse(
          ciphertext.slice(ivLength + hmacLength)
        );

        const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: ciphertextRaw },
          CryptoJS.enc.Utf8.parse(key),
          { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );

        // Verificar HMAC
        const calculatedHmac = CryptoJS.HmacSHA256(
          ciphertextRaw,
          CryptoJS.enc.Utf8.parse(key)
        );
        if (
          calculatedHmac.toString() !==
          CryptoJS.enc.Latin1.parse(hmac).toString(CryptoJS.enc.Hex)
        ) {
          throw new Error("HMAC verification failed");
        }

        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error("Error al desencriptar:", error);
        throw new Error("No se pudo desencriptar el ID del documento");
      }
    },

    drawColumnHeaders(ctx, y) {
      ctx.font = this.FONT_SIZES.MEDIUM;
      ctx.textAlign = "start";
      ctx.fillText("Item", this.columns.item.x, y);
      ctx.fillText("Descripción", this.columns.description.x, y);
      ctx.textAlign = "end";
      ctx.fillText("Cant.", this.columns.quantity.x + this.columns.quantity.width, y);
      ctx.fillText("Valor", this.columns.value.x + this.columns.value.width, y);
    },
    drawProductRow(ctx, row, index, y) {
      const lineHeight = 50; // Adjust this value as needed
      ctx.font = this.FONT_SIZES.MEDIUM;

      // Draw item number (index)
      ctx.textAlign = "start";
      ctx.fillText(index.toString(), this.columns.item.x, y);

      // Draw product description (single line)
      const description = `${row.product_name} ${row.size} ${row.uom}`;
      const maxWidth = this.columns.quantity.x - this.columns.description.x - 10; // Leave some space before quantity
      ctx.fillText(
        this.truncateText(ctx, description, maxWidth),
        this.columns.description.x,
        y
      );

      // Move to next line for tax info, quantity, and price
      y += lineHeight;

      // Draw tax info
      ctx.font = this.FONT_SIZES.SMALL;
      let taxInfo = "";
      if (row.discount_value > 0) {
        taxInfo = `IVA: $${
          row.tax_value
        } / Descuento: $${row.discount_value.toLocaleString()}`;
      } else if (row.tax > 0) {
        taxInfo = `IVA ${row.tax}% : ${row.tax_value.toLocaleString()}`;
      }
      ctx.fillText(taxInfo, this.columns.description.x, y);

      // Draw quantity
      ctx.font = this.FONT_SIZES.MEDIUM;
      ctx.textAlign = "end";
      ctx.fillText(
        row.qty.toString(),
        this.columns.quantity.x + this.columns.quantity.width,
        y
      );

      // Draw price
      ctx.fillText(
        row.tax_base.toLocaleString(),
        this.columns.value.x + this.columns.value.width,
        y
      );

      // Return the new y position
      return y + lineHeight; // Add space for the next product
    },
    // Add this helper method to truncate text if it's too long
    truncateText(ctx, text, maxWidth) {
      let truncated = text;
      while (ctx.measureText(truncated).width > maxWidth && truncated.length > 0) {
        truncated = truncated.slice(0, -1);
      }
      return truncated;
    },
    drawWrappedText(ctx, text, x, y, maxWidth) {
      const words = text.split(" ");
      let line = "";
      const lineHeight = 30;
      let totalHeight = 0;

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
          totalHeight += lineHeight;
        } else {
          line = testLine;
        }
      }
      ctx.fillText(line, x, y);
      totalHeight += lineHeight;

      return totalHeight;
    },
    async loadDocument() {
      this.loading = true;
      this.error = null;
      // console.log("encryptedId", this.encryptedId);
      try {
        this.decryptedId = this.decryptId(this.encryptedId);
        if (!this.decryptedId) {
          throw new Error("La desencriptación produjo un resultado vacío");
        }
        const dta = this.decryptedId.split("_");
        var qry = {
          store: dta[0],
          issueDate: dta[1],
          did: dta[2],
        };
        let promise = new Promise((resolve, reject) => {
          webserver(
            "get_invoice_fe",
            qry,
            function (data) {
              resolve(data);
            },
            function () {
              reject([]);
            }
          );
        });
        let response = await promise;

        this.item = response;
        if (this.item.document.invoice === "") {
          this.save_invoice();
        } else {
          if (this.item.document.cufe === "") {
            this.e_invoice_fe_C();
          } else {
            this.open_dian_response();
          }
          this.e_invoice_fe_C();
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    save_invoice() {
      const timestamp = this.item.document.issuedate; // Replace with your actual timestamp string
      const [date] = timestamp.split(/\s+/);
      var invoice = {
        did: this.item.document.doc_number,
        pos_issueDate: date,
        store: this.item.document.store,
        doc_type: this.item.document.doc_type,
      };

      this.loading_status = true;
      webserver("post_invoice_complete_contingencia", invoice, (data) => {
        this.loading_status = false;
        this.item = data;
        if (this.item.document.invoice) {
          this.e_invoice_fe_C();
        }
      });
    },
    e_invoice_fe_C() {
      var custumer = this.item.buyer;
      var itms = this.item.items;
      var doc = this.item.document;
      var company = this.item.company;
      var resolution = this.item.permit.InvoiceAuthorization;
      var syncCode = true;

      if (company.sync_code === "1") {
        syncCode = true;
      } else {
        syncCode = false;
      }

      var type_organization = 2;
      if (custumer.PartyTypeId === "6") {
        type_organization = 1;
      } else {
        type_organization = 2;
      }

      const timestamp = this.item.document.issuedate; // Replace with your actual timestamp string
      const [date, time] = timestamp.split(/\s+/);

      var eInv = {
        number: doc.invoice.split("-")[1],
        sync: syncCode,
        type_document_id: 3,
        date: date,
        time: time,
        additional_document_references: [
          {
            number: doc.invoice,
          },
        ],

        branch_office: {
          name: this.item.company.PartyName,
          phone: this.item.company.client_phone,
          address: this.item.company.AddressLine,
          trade_name: this.item.company.PartyName,
          municipality_id: this.item.company.city_code,
        },
        customer: {
          type_document_identification_id: custumer.PartyTypeId,
          identification_number: custumer.PartyIdentification,
          type_organization_id: type_organization,
          name: custumer.PartyName,
          address: custumer.AddressLine,
          municipality_id: custumer.municipality_id,
          email: custumer.PartyEmail,
        },
        legal_monetary_totals: {
          line_extension_amount: 0,
          tax_exclusive_amount: 0,
          tax_inclusive_amount: 0,
          allowance_total_amount: 0,
          charge_total_amount: 0,
          payable_amount: 0,
        },
        invoice_lines: [],
      };

      var tax_value = 1.19;
      var taxvalue = 19.0;

      itms.forEach((item) => {
        item.item_quantity = parseFloat(item.item_quantity);
        item.price_amount = (parseFloat(item.item_price) / tax_value).toFixed(2);
        item.item_discount = parseFloat(item.item_discount).toFixed(2);
        item.tax = (
          (parseFloat(item.price_amount) * parseFloat(item.item_quantity) * taxvalue) /
          100
        ).toFixed(2);

        //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
        item.line_extension_amount = (
          parseFloat(item.price_amount) * parseFloat(item.item_quantity)
        ).toFixed(2);
      });

      itms.forEach((item) => {
        if (item.item_quantity > 0) {
          var itm = {
            description: item.item_concept,
            unit_measure_id: 938,
            code: item.item_code,
            type_item_identification_id: 3,
            base_quantity: item.item_quantity.toFixed(6),
            invoiced_quantity: item.item_quantity.toFixed(6),
            price_amount: item.price_amount,
            line_extension_amount: item.line_extension_amount,
          };
          var leo = parseFloat(itm.line_extension_amount);
          if (leo == 0) {
            itm.reference_price_id = 1;
            itm.price_amount = parseFloat(item.item_discount).toFixed(2);
          }
          if (parseFloat(item.item_discount) > 0) {
            itm.allowance_charges = [
              {
                charge_indicator: false,
                allowance_charge_reason: "Discount",
                amount: parseFloat(item.item_discount) * parseFloat(item.item_quantity),
                base_amount: (
                  (parseFloat(item.price_amount) + parseFloat(item.item_discount)) *
                  parseFloat(item.item_quantity)
                ).toFixed(2),
              },
            ];
            eInv.legal_monetary_totals.allowance_total_amount += parseFloat(
              item.discount
            );
          }

          itm.tax_totals = [
            {
              tax_id: 1,
              tax_amount: item.tax,
              taxable_amount: (
                parseFloat(item.price_amount) * parseFloat(item.item_quantity)
              ).toFixed(2),
              percent: taxvalue.toFixed(2),
            },
          ];

          eInv.legal_monetary_totals.line_extension_amount += parseFloat(
            itm.line_extension_amount
          ); //Valores totales aplicables a la factura
          eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
            item.line_extension_amount
          ); // Total Valor Bruto (Antes de tributos)
          eInv.legal_monetary_totals.tax_inclusive_amount +=
            parseFloat(item.line_extension_amount) + parseFloat(item.tax);
          eInv.legal_monetary_totals.charge_total_amount +=
            parseFloat(item.line_extension_amount) + parseFloat(item.tax);
          eInv.legal_monetary_totals.payable_amount +=
            parseFloat(item.line_extension_amount) + parseFloat(item.tax);
          eInv.invoice_lines.push(itm);
        }
      });

      var payments = this.item.payments;
      var bags = payments.find((item) => item.tipo === "ICB ley 1819 de 2016");
      if (bags) {
        bags.quantity = parseFloat(bags.valor) / 66;
        bags.tax = 66;
        var itmBags = {
          description: "Bolsas",
          unit_measure_id: 886,
          code: "000000000000",
          type_item_identification_id: 3,
          base_quantity: "1.000000",
          invoiced_quantity: bags.quantity.toFixed(6),
          price_amount: parseFloat(bags.valor).toFixed(2),
          line_extension_amount: "0.00",
          reference_price_id: 1,
          tax_totals: [
            {
              tax_id: 10,
              tax_amount: parseFloat(bags.valor).toFixed(2),
              taxable_amount: parseFloat(bags.quantity).toFixed(2),
              unit_measure_id: 886,
              per_unit_amount: bags.tax.toFixed(2),
              base_unit_measure: bags.quantity.toFixed(6),
            },
          ],
          //  parseFloat(bags.quantity).toFixed(2),
        };
        //
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itmBags.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          itmBags.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.invoice_lines.push(itmBags);
      }

      eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(
        2
      ); //Valores totales aplicables a la factura
      eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(
        2
      ); // Total Valor Bruto (Antes de tributos)
      eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(
        2
      );

      this.put_invoice_c(eInv, company, resolution);
    },
    put_invoice_c(data, company, resolution) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: company.PartyIdentification,
        environment: parseInt(company.environment),
        data: data,
        store: company.store_code,
        issueDate: this.item.document.issuedate,
        did: this.item.document.doc_number,
        resolution: resolution,
      };
      fe_ws("post_invoice_c", qry, (data) => {
        this.loading_status = false;

        if (data.data.errors) {
          console.log("error:", data);
        } else {
          var url_cufe =
            "https://catalogo-vpfe-hab.dian.gov.co/document/searchqr?documentkey=";

          if (this.item.company.environment && this.item.company.environment === "1") {
            url_cufe = "https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=";
          }
          window.location.href = url_cufe + data.cufe;
        }
      });
    },
    open_dian_response() {
      var url_cufe =
        "https://catalogo-vpfe-hab.dian.gov.co/document/searchqr?documentkey=";

      if (this.item.company.environment && this.item.company.environment === "1") {
        url_cufe = "https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=";
      }
      window.location.href = url_cufe + this.item.document.cufe;
    },
  },
};
</script>

<style scoped>
.v-overlay__scrim {
  background-color: rgba(0, 0, 0, 1); /* Fondo completamente negro */
}
</style>
